import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, of, switchMap, throwError, firstValueFrom, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from 'src/app/error-handler.service';
import { ThemeService } from '@core/services/theme.service';
import { Router } from '@angular/router';
import { signOut, getAuth } from 'firebase/auth';
import { Company } from '@core/models/company';
import { CompanyService } from '@core/services/company.service';
import { GlobalAppConfigurationService } from '@core/services/global-app-configuration.service';

import { EvalAccountParameters } from '../models/eval-account-parameters';
import { TenableChildAccount } from '../models/tenable-child-account';
import { User } from '@core/models/user';


@Injectable({
  providedIn: 'root'
})
export class MsspService {

  private defaultCacheDuration = 7 * 24 * 60 * 60 * 1000; // Default cache duration: 7 days in milliseconds
  public companySlug?: string;
  private functionUrl = environment.mssp_function_url + '/api/tenable';
  private companyHeaderObject?: { company_slug: string; };
  public apiTokenSource = new BehaviorSubject<string | null>(null);
  public apiToken$ = this.apiTokenSource.asObservable();

  constructor(
    private http: HttpClient,
    private globalConfigService: GlobalAppConfigurationService,
    private companyService: CompanyService,
    private errorService: ErrorHandlerService,
    private themeService: ThemeService,
    private router: Router

  ) {
    // this.setCompanySlug();
    this.globalConfigService.getMsspFunctionToken().then((tokenData: any) => {
      if (tokenData) {
        this.apiTokenSource.next(tokenData.value);
      }
    })
  }

  /**
   * Sets the company slug for the current user.
   */
  setCompanySlug() {
    this.companyService.getCompanySlug().subscribe((slug: string | null) => {
      if (slug) {
        this.companySlug = slug;
        this.companyHeaderObject = { "company_slug": this.companySlug }
      }
    });
  }

  /**
   * Sets the token for the Tenable Functions Service.
   * @returns Promise<void>
   */
  setToken(): Promise<void> {
    try {
      this.setCompanySlug();
      return this.globalConfigService.getMsspFunctionToken().then((tokenData: any) => {
        if (tokenData) {
          this.apiTokenSource.next(tokenData.value);
        }
      });
    } catch (error) {
      console.error("Error in Set Token Tenable Functions Service ", error);
      this.errorService.handleError(error);
      return Promise.resolve();
    }
  }

  /**
  * Handles HTTP errors by extracting the error message and returning it as an Observable error.
  * @param error The error object caught by catchError.
  * @returns An Observable that emits an error with a custom or generic error message.
  */
  private handleError(error: any): Observable<never> {
    const errorMessage = error.error?.message || 'An unexpected error occurred';
    if (this.errorService)
      this.errorService.handleError(error);
    return throwError(() => new Error(errorMessage));
  }

  /**
   * Retrieving from Cache: Before making an HTTP call, we'll first check if a valid cached version of the data exists. If it does, and it's not older than 7 days, we'll use that instead of making a new HTTP call.
   * @param key
   * @returns
   */
  private getCachedResponse(key: string): any {
    const cached = sessionStorage.getItem(key);
    if (!cached) return null;

    const { data, timestamp, duration } = JSON.parse(cached);
    const isExpired = Date.now() - timestamp > duration;
    return isExpired ? null : data;
  }

  /**
   * Caching Responses: When the HTTP call is made and a response is received, we will store it in the browser's local storage along with a timestamp. This will allow us to check if the cached data is still valid (i.e., not older than 7 days).
   * @param key
   * @param data
   * @param duration
   */
  private setCache(key: string, data: any, duration: number = this.defaultCacheDuration): void {
    const cacheEntry = {
      timestamp: Date.now(),
      data: data,
      duration: duration
    };
    sessionStorage.setItem(key, JSON.stringify(cacheEntry));
  }


  // - Create evaluation account v2 - `CreateEvalAccount`
  // - path: `/api/tenable/create-eval-account`
  // /api/tenable/create-eval-account


  createEvalAccount(accountParameters: EvalAccountParameters): Observable<TenableChildAccount | any> {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };
        const payload = accountParameters;
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + `/create-eval-account`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );

  }


  // - List child accounts - `ListChildAccounts`
  // - path: `/api/tenable/list-child-accounts`
  // - https://developer.tenable.com/reference/io-mssp-accounts-list
  /**
   * Returns a list of child accounts in the Tenable MSSP Portal.
   * @returns any[]
   */
  listChildAccounts(): Observable<TenableChildAccount[] | any> {
    return this.apiToken$.pipe(
      filter(token => token !== null), // Only proceed if token is not null
      switchMap(token => {
        if (!token) {
          // This check might be redundant due to the filter above, consider removing
          return of([]); // Return an Observable that emits an empty array
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        return this.http.post(this.functionUrl + '/list-child-accounts', this.companyHeaderObject, httpOptions).pipe(
          catchError(this.handleError)
        );

      })
    );
  }


  /**
   *
   * Returns details for the specified child account.
   * @param uuid string
   * @returns any
   */
  getChildAccounts(uuid: string) {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          uuid: uuid
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + `/get-child-account-details/${uuid}`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  // - Get widget details - `GetWidgetDetails`
  // - path: `/api/tenable/get-widget-details`
  // - https://developer.tenable.com/reference/io-mssp-dashboard-details
  /**
   * Returns the data for the specified dashboard widget.
   * @retuns any[]
   */
  getWidgetDetails() {
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const payload = {
          widget: "scan_results"
        };
        const finalPayload = { ...payload, ...this.companyHeaderObject };

        return this.http.post(this.functionUrl + '/get-widget-details', finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }


  /**
 * Creates an MSSP account and updates the company data with the account details.
 *
 * @param {Company} companyData - The data of the company for which the MSSP account is being created.
 * @param {User} user - The user initiating the MSSP account creation.
 * @returns {Promise<TenableChildAccount>} - A promise that resolves with the created account details.
 */
  createMsspAccount(companyData: Company, user: User): Promise<TenableChildAccount> {
    return new Promise((resolve, reject) => {
      const randomString: string = this.generateRandomString();
      const companyModelName = companyData.name.replace(/\s+/g, '-').toLowerCase();
      const admin_username = `${companyModelName}-${randomString}@labworkz.com`;

      firstValueFrom(this.companyService.getCompanySlug())
        .then(companySlug => {
          const evalAccount: EvalAccountParameters = new EvalAccountParameters({
            customer_name: companyData.domain,
            company_slug: companySlug,
            licensed_apps: [
              "vm",
              "was",
              "asm",
              "cns",
              "pci",
              "one",
              "lumin",
              "ad"
            ],
            default_contact_email: user.email,
            default_admin_username: admin_username,
            country: "US"
          });

          this.createEvalAccount(evalAccount).subscribe({
            next: (evalAccount: TenableChildAccount) => {
              if (evalAccount) {
                companyData.accountUuid = evalAccount.uuid;
                this.companyService.update(companyData, companyData.uid)
                  .then(() => {
                    resolve(evalAccount);
                  })
                  .catch((error) => {
                    console.error("Error updating company data:", error);
                    this.errorService.handleError(error);
                    reject(error);
                  });
              } else {
                this.errorService.handleError("EvalAccount creation failed.");
                reject(new Error("EvalAccount creation failed."));
              }
            },
            error: (error) => {
              console.error("Error creating EvalAccount:", error);
              this.errorService.handleError(error);
              reject(error);
            }
          });
        })
        .catch(error => {
          console.error("Error fetching company slug:", error);
          this.errorService.handleError(error);
          reject(error);
        });
    });
  }


  generateRandomString(length: number = 8): string {
    const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * letters.length);
      randomString += letters.charAt(randomIndex);
    }
    return randomString;
  }


  validateAccount(accountModel: TenableChildAccount, companyModel: Company): boolean {
    // If accountModel is undefined or {} return false
    if (!accountModel || Object.keys(accountModel).length === 0) {
      console.error('mssp 315: An error occurred: No Account Found. Contact Support. Logging out..');
      this.themeService.triggerError('An error occurred: No Account Found. Contact Support. Logging out..');
      return false;
    }


    if (this.isAccountExpired(accountModel)) {
      return false;
    }

    if (companyModel.accountUuid == null) {
      companyModel.accountUuid = accountModel.uuid;
      this.companyService.update(companyModel, companyModel.uid).then(() => {
        const mspAccount = new TenableChildAccount(accountModel);
        this.themeService.setAccountModel(mspAccount);
      });
    }

    sessionStorage.setItem('accountDetails', JSON.stringify(accountModel));
    return true;
  }

  isAccountExpired(accountModel: TenableChildAccount): boolean {
    const licenseExpirationDate: Date = new Date(accountModel.license_expiration_date * 1000);
    const now: Date = new Date();

    if (accountModel.license_expiration_date === 0 || licenseExpirationDate < now) {
      const accountError = {
        error: accountModel.license_expiration_date === 0
          ? "License expiration date is not set."
          : "Account is expired.",
        accountModel: accountModel
      };
      sessionStorage.setItem('accountError', JSON.stringify(accountError));
      return true;
    } else {
      return false;
    }
  }


  checkMsspAccountDetails(companyModel: Company) {
    try {
      // Check if account details are stored in session storage
      const storedAccountDetails = sessionStorage.getItem('accountDetails');
      if (storedAccountDetails) {
        const accountModel = JSON.parse(storedAccountDetails);
        // Proceed with using the stored account details
        if (accountModel.container_name != companyModel.domain) {
          console.error(' Mssp 364: An error occurred: Account details do not match company domain. Logging out..');
          this.themeService.triggerError('An error occurred: No Account Found. Contact Support. Logging out..');
          this.logoutUser();
        }

      } else {
        // If not stored, proceed to fetch and store account details
        // this.themeService.showSpinner();
        this.listChildAccounts().subscribe((res: any) => {
          if (res && companyModel) {
            const accountModel = res.accounts.filter((company: any) => company.container_name === companyModel.domain)[0];
            const validAccount = this.validateAccount(accountModel, companyModel);
            if (!validAccount) {
              this.logoutUser();
            }
          }
        });
      }
    } catch (error: any) {
      console.error('An error occurred: ' + error.message);
    }
  }

  async logoutUser() {

    await signOut(getAuth());
    sessionStorage.removeItem('user');
    setTimeout(() => {
      return this.router.navigate(['/login']);
    }, 5000)
  }


  /**
   * Retrieves the account details for the specified user.
   * @param user User
   * @returns Observable<any>
   */
  getUserAccountDetails(user: User): Observable<any> {
    const cacheKey = 'account-details'
    const cachedResponse = this.getCachedResponse(cacheKey);
    if (cachedResponse) return of(cachedResponse);

    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }

        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...{ 'email': user.email }, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/user-account-details`, finalPayload, httpOptions).pipe(
          tap(response => this.setCache(cacheKey, response)),
          catchError(this.handleError)
        );
      })
    );
  }

  /**
   * Creates a user account for the specified user.
   * @returns Observable<any>
   */
  createUserAccount(user: User): Observable<any> {
    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const username_postfix = this.generate_random_string(4);

        const userPayload = {
          "username": this.companySlug + "_user" + username_postfix + "@labworkz.com",
          "email": user.email,
          "password": this.generatePassword(),
          "permissions": 64,
        }

        const finalPayload = { ...{}, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/create-user-account`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }


  generate_random_string(number: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < number; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;

  }

  /**
   *
   * Generates a random password that is at least 12 characters long and
   * contains at least one uppercase letter, one lowercase letter,
   * one number, and one special character symbol.
   */
  generatePassword() {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const symbols = '!@#$%^&*()_+[]{}|;:,.<>?';
    const all = lowercase + uppercase + numbers + symbols;

    let password = '';
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += symbols[Math.floor(Math.random() * symbols.length)];


    for (let i = 0; i < 8; i++) {
      password += all[Math.floor(Math.random() * all.length)];
    }

    // Shuffle the password
    return password.split('').sort(() => Math.random() - 0.5).join('');
  }

  /**
   * Retrieves the user credentials for the specified user ID.
   * @param user_id The ID of the user.
   * @returns An Observable that emits the user credentials.
   */
  getUserCredentials(user_id: string): Observable<any> {
    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return of([]); // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...{ user_id: user_id }, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/user-credentials`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }

  /**
   * Creates user credentials for the specified user ID.
   * @param user_id The ID of the user.
   * @returns An Observable that emits the user credentials.
   */
  createUserCredentials(user_id: string): Observable<any> {
    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        const finalPayload = { ...{ user_id: user_id }, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/create-user-credentials`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );
  }


  /**
   * Creates client credentials for the specified user.
   * @param user The user for which the client credentials are being created.
   * @returns An Observable that inicates the success or failure of the operation.
   */
  createClientCredentials(user: User): Observable<any> {
    this.setToken();
    return this.apiToken$.pipe(
      filter(token => token !== null),
      switchMap(token => {
        if (!token) {
          // Handle case where token is not yet available
          return []; // Return an empty observable or handle as needed
        }
        if (!this.companyHeaderObject) {
          throw new Error('Company Header Not Set');
        }
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-functions-key': token
          })
        };

        let accountDetails = JSON.parse(sessionStorage.getItem('accountDetails') || '{}');
        if(!accountDetails || !accountDetails.uuid) {
          throw new Error('Account Details Not Found');
        }

        const newPayload = {
          "container_uuid": accountDetails.uuid,
          "default_contact_email": user.email,
          "company_slug": this.companySlug
        }
        const finalPayload = { ...newPayload, ...this.companyHeaderObject };
        return this.http.post(this.functionUrl + `/create-new-user-account`, finalPayload, httpOptions).pipe(
          catchError(this.handleError)
        );
      })
    );

  }
}

